<template>
  <Modal
    :show="showModal"
    :title="$t('GorevPlani.otomatik_duzenle')"
    save-text="Kaydet"
    @close="$emit('showModal',false)"
    @saveMethod="Olustur"
  >
    <!--title="Görev Planı Güncelle"-->
    <template v-slot:form>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="old_data.personeller"
                :items="personeller"
                chips
                hide-details
                label='Personel'
                item-text="name"
                item-value="id"
                :menu-props="{contentClass:'list-style'}"
                multiple
              >
                <template #selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    dense
                    label
                    @click="data.select"
                    @click:close="Remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="`/img/${data.item.avatar}`"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>

                <template #item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>

                  <template v-else>
                    <v-list-item-avatar>
                      <v-img :src="`/img/${data.item.avatar}`"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.group }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="old_data.musteri_id"
                :items="Musteriler"
                hide-details
                :label="$t('GorevPlani.MUSTERI_SECIN')"
                item-text="adi"
                item-value="id"
                @change="MusteriSecildi"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="old_data.adres_id"
                :items="MusteriAdresleri"
                hide-details
                :label="$t('GorevPlani.ADRES_SECIN')"
                :item-text="item => `${item.cadde_nr} , ${item.postakodu_sehir} , ${item.ulke}`"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="old_data.baslama_tarihi"
                :label="$t('GorevPlani.baslama_tarihi')"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="old_data.baslama_saati"
                :label="$t('GorevPlani.baslama_saati')"
                type="time"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="old_data.bitis_tarihi"
                :label="$t('GorevPlani.bitis_tarihi')"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="old_data.bitis_saati"
                :label="$t('GorevPlani.bitis_saati')"
                type="time"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
  </Modal>
</template>

<script>
import { mdiWindowClose } from '@mdi/js'
import Modal from '@/components/Modal'
import PersonelSelect from '@/components/PersonelSelect'

export default {
  name: 'GorevPlaniPersonelOtomatikGuncelle',
  components: { PersonelSelect, Modal },
  props: ['editdata', 'showModal'],
  data() {
    return {
      show: true,
      data: {
        personel: null,
        musteri_id: null,
        adres_id: null,
        baslama_tarihi: this.Today(),
        bitis_tarihi: this.After(),
        baslama_saati: '09:00',
        bitis_saati: '19:00',
      },
      icons: {
        mdiWindowClose,
      },
      old_data: { },
    }
  },
  computed: {
    Musteriler() {
      return this.$store.state.Musteriler.Musteriler
    },
    personeller() {
      return this.$store.state.Personeller.PersonelList
    },
    MusteriAdresleri() {
      return this.$store.state.Musteriler.MusteriAdresleri
    },
    PersonelSelect() {
      return this.$store.state.Personeller.SelectPersonel
    },
  },
  mounted() {
    const veri = { ...this.editdata }
    veri.personeller = veri.personeller.split(',')
    this.old_data = veri
    console.log(this.old_data)
    this.$store.dispatch('Action', { name: 'Musteriler/MusterilerListesi' })
    this.$store.dispatch('Action', { name: 'Personeller/PersonellerAutocomplate' })
  },
  methods: {
    Remove(item) {
      const index = this.old_data.personeller.findIndex(elem => item.id === elem)
      this.old_data.personeller.splice(index, 1)
    },
    Today() {
      return this.DateFormat(new Date())
    },
    After() {
      const afterDay = 7 // Personel Ekleme yaparken varsayılan olarak 7 gün sonrası gelir.
      const date = new Date()
      date.setDate(date.getDate() + afterDay)

      return this.DateFormat(date)
    },
    DateFormat(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    MusteriSecildi(item) {
      const data = { id: item }
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriTumu', data }).then(() => {
        const adresSayisi = this.MusteriAdresleri?.length || 0
        // eslint-disable-next-line prefer-destructuring
        if (adresSayisi === 1) this.old_data.adres_id = this.MusteriAdresleri[0].id
      })
    },
    async Olustur() {
      this.data.personel = this.old_data.personeller
      this.data.islem_kodu = this.old_data.islem_kodu
      this.data.adres_id = this.old_data.adres_id
      this.data.firma_id = this.old_data.firma_id
      this.data.musteri_id = this.old_data.musteri_id
      this.data.baslama_tarihi = this.old_data.baslama_tarihi
      this.data.baslama_saati = this.old_data.baslama_saati
      this.data.bitis_tarihi = this.old_data.bitis_tarihi
      this.data.bitis_saati = this.old_data.bitis_saati
      this.data.manuel_guncelleme = this.old_data.manuel_guncelleme
      await this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniSilTekrarOlustur', data: this.data })
      this.$emit('showModal', false)
      this.$emit('setReload',true)
      this.data = {
        personel: null,
        musteri_id: null,
        adres_id: null,
        baslama_tarihi: null,
        bitis_tarihi: null,
        baslama_saati: null,
        bitis_saati: null,
      }
    },
  },

}
</script>

<style scoped>

</style>
