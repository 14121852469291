<template>
  <v-dialog
    v-model="show"
    transition="dialog-top-transition"
    max-width="900"
    persistent
  >
    <template v-slot:default>
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          ({{ old_data.from + " - " + old_data.to }})  {{ $t('GorevPlani.manuel_guncelle') }}
          <v-spacer />
          <v-btn
            icon
            color="grey"
            @click="$emit('close')"
          >
            <v-icon>{{ icons.mdiWindowClose }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-simple-table class="my-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(header,x) in old_data.headers"
                      :key="header.value"
                      class="text-center"
                      :colspan="x>0 ? 4 : 1"
                    >
                      {{ translateDay(header.text) }}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th
                      v-for="i in CalculatedHeaderLength"
                      :key="i"
                      colspan="2"
                    >
                      {{ i % 2 == 0 ? "Bitiş Saat" : "Başla Saat" }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in old_data.items"
                    :key="i"
                  >
                    <td>{{ item[0].soyisim + ',' + item[0].isim }}</td>
                    <td
                      v-for="c in item"
                      :key="c.gorev_id"
                      colspan="4"
                    >
                      <v-row>
                        <v-col class="d-flex">
                          <v-text-field
                            v-model="c.baslama_saati"
                            type="time"
                            required
                          ></v-text-field>

                          <v-text-field
                            v-model="c.bitis_saati"
                            class="ml-5"
                            type="time"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                <!--<tr
                  v-for="(item,i) in old_data.items" :key='i'
                >
                  <td>{{ item[0].soyisim+","+item[0].isim }}</td>
                  <td colspan='4'
                      v-for='c in item' :key='c.gorev_id'
                  >
                    <v-row>
                      <v-col md='6'>

                        <v-text-field
                          v-model="c.baslama_saati"
                          type="time"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col md='6'>
                        <v-text-field
                          v-model="c.bitis_saati"
                          type="time"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </td>

                </tr>-->
                </tbody>
              </template>
            </v-simple-table>

            <!--<v-data-table
              :headers="old_data.headers"
              class="table"
              :items="old_data.items"
              :search="arama"
              hide-default-header
            >
              <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                  <tr>
                    <th
                      v-for="(header,x) in headers"
                      :key="header.value"
                      class="text-center"
                      :colspan="x>0 ? 4 : 1"
                    >
                      {{ translateDay(header.text) }}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th
                      colspan='2'
                      v-for="i in CalculatedHeaderLength"
                      :key="i"
                    >
                      {{ i % 2 == 0 ? "Bitiş Saat" : "Başla Saat" }}
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item,headers }">
                <tr>
                  <td>{{ item[0].soyisim+","+item[0].isim }}</td>

                  <td colspan='4'
                  v-for='c in item' :key='c.gorev_id'
                  >
                    <v-row>
                      <v-col md='6'>
                        <v-text-field
                          v-model="c.baslama_saati"
                          type="time"
                          required
                        ></v-text-field>
                      </v-col>
                        <v-col md='6'>
                          <v-text-field
                            v-model="c.bitis_saati"
                            type="time"
                            required
                          ></v-text-field>
                        </v-col>

                    </v-row>

                  </td>

                </tr>
              </template>
            </v-data-table>-->
            <!-- <v-expansion-panels class="my-2">
                <v-expansion-panel
                  v-for="(personel,index) in old_data.items"
                  :key="index"
                >
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col md="6">
                        {{ personel[0].isim+' '+personel[0].soyisim }}
                      </v-col>
                      <v-col md="6">
                        <v-btn
                          text
                          icon
                          color="primary"
                          class="float-right"
                          @click.native.stop="gorevPlanindanSil(personel)"
                        >
                          <v-icon>
                            {{ icons.mdiTrashCanOutline }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-for="(data,i) in personel"
                    :key="index+'_'+i"
                    :style="i % 2 ==0 ? 'background:lightgray;' : ''"
                  >
                    <v-row>
                      <v-col md="12">
                        <v-autocomplete
                          v-model="data.personel_id"
                          :items="PersonellerListesi"
                          item-value="id"
                          :item-text="item => (item.isim+' '+item.soyisim)"
                          dense
                          label="Personel"
                          class="mt-4"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="6">
                        <v-autocomplete
                          v-model="data.musteri_id"
                          :items="Musteriler"
                          hide-details
                          label="Müsteri Seçin"
                          item-text="adi"
                          item-value="id"
                          @change="MusteriSecildi(data.musteri_id,index,i)"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col md="6">
                        <v-autocomplete
                          v-model="data.adres_id"
                          :items="data.musteri_adresleri"
                          hide-details
                          label="Adres"
                          :item-text="item => `${item.cadde_nr} , ${item.postakodu_sehir} , ${item.ulke}`"
                          item-value="id"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="data.calisma_tarihi"
                          label="Çalışma Tarihi"
                          type="date"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="data.bitis_saati"
                          label="Bitiş Saati"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
          </v-row>
          <div
            class="justify-center align-center my-3"
            style="display: flex"
          >
            <v-btn
              color="primary"
              @click="gorevPlaniGuncelle"
            >
              KAYDET
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mdiWindowClose, mdiTrashCanOutline, mdiAccountDetails } from '@mdi/js'

export default {
  name: 'GorevPlaniPersonelGuncelle',
  props: ['editdata'],
  data: () => ({
    show: true,
    data: {
      id: '',
      isim: '',
      personeller: [],
      aciklama: '',
    },
    evrak_gurubu: [
      { name: 'Personaldaten' },
      { name: 'Qualifikation' },
      { name: 'Übernachtung' },
      { name: 'Überlassung' },
      { name: 'Arbeitsmedizin' },
    ],
    icons: {
      mdiWindowClose, mdiTrashCanOutline, mdiAccountDetails,
    },
    old_data: {},
    arama: '',
  }),
  computed: {
    CalculatedHeaderLength() {
      if (Array.isArray(this.old_data.headers)) {
        return (this.old_data.headers.length - 1) * 2
      }

      return 0
    },
    Musteriler() {
      return this.$store.state.Musteriler.Musteriler
    },
    PersonellerListesi() {
      return this.$store.state.Personeller.Personeller
    },
    MusteriAdresleri() {
      console.log(this.$store.state.Musteriler)

      return this.$store.state.Musteriler.MusteriAdresleri
    },
  },
  mounted() {
    const veri = { ...this.editdata }
    this.old_data = veri
    console.log('Edit Modal loaded')
  },
  methods: {

    getRelatedData(items, headers) {
      let founded = {}
      const newArr = []
      // eslint-disable-next-line no-unused-vars
      Object.keys(items).forEach((c, i) => {
        newArr.push(items[c])
      })
      for (let i = 0; i < headers.length; i++) {
        const header = headers[i]
        // eslint-disable-next-line eqeqeq
        const cx = newArr.find(c => c.calisma_tarihi.substr(0, 10) == header.value)
        if (cx != undefined) {
          founded = cx
        }
      }

      return founded
    },
    translateDay(day) {
      if (!day.includes('-')) return `${day.split(' ')[0]} ${this.$t(day.split(' ')[1])}`

      const [baslama, bitis] = day.split('-')

      return `${baslama.split(' ')[0]} ${this.$t(baslama.split(' ')[1])} - ${bitis.split(' ')[0]} ${this.$t(bitis.split(' ')[1])} `
    },

    gorevPlanindanSil(personel) {
      // eslint-disable-next-line no-use-before-define
      const x = confirm('Personeli Takvimden Silmek İstediğinize Emin Misiniz?')
      if (x) {
        this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniPersonelSil', data: personel }).then(() => {
          this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniListesi' })
          this.$emit('close')
        }).catch(() => {
          setTimeout(() => {
            this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniListesi' })
            this.$emit('close')
          }, 700)
        })
      }
    },
    MusteriSecildi(id, index, i) {
      const data = { id }
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriTumu', data }).then(() => {
        if (this.MusteriAdresleri.length == 1) {
          this.old_data.items[index][i].adres_id = this.MusteriAdresleri[0].id
        }
        this.old_data.items[index][i].musteri_adresleri = this.MusteriAdresleri
      })
    },
    async gorevPlaniGuncelle() {
      const data = []
      // eslint-disable-next-line no-plusplus,no-empty
      this.old_data.items.forEach((items, k) => {
        // eslint-disable-next-line no-shadow
        Object.keys(items).forEach(k => {
          data.push(items[k])
        })
      })
      console.log(data)
      this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniPersonelGuncelle', data }).then(() => {
        console.log(data)
        // eslint-disable-next-line eqeqeq
        if (this.$route.name == 'gorev-mutabakat') {
          console.log(this.$route)
          this.$emit('setReload', true)
        } else {
          this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniListesi' })
        }
        this.$emit('close')
      }).catch(() => {
        setTimeout(() => {
          this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniListesi' })
          this.$emit('close')
        }, 700)
      })
    },
  },

}
</script>
<style scoped>
.my-table > .v-data-table__wrapper > table,th,td {
  border:1px solid black;
}
</style>
